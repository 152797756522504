
import type { PropType } from 'vue'
import type { ReportingMessagesStatsFullLine } from '@/definitions/reporting/messagesCalls/messages/types'
import { defineComponent } from 'vue'
import ReportingMessagesCallsTableFilterFull from '@/components/pages/reporting/messagesCalls/ReportingMessagesCallsTableFilterFull.vue'
import ReportingMessagesTableFull from '@/components/pages/reporting/messagesCalls/messages/ReportingMessagesTableFull.vue'
import ReportingMessagesStats from '@/components/pages/reporting/messagesCalls/messages/ReportingMessagesStats.vue'

export default defineComponent({
  components: {
    ReportingMessagesCallsTableFilterFull,
    ReportingMessagesTableFull,
    ReportingMessagesStats,
  },
  props: {
    statsFull: {
      type: Object as PropType<ReportingMessagesStatsFullLine[]>,
      required: true,
    },
  },
})
