
import type { PropType } from 'vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ReportingMessagesStatsFullLine } from '@/definitions/reporting/messagesCalls/messages/types'
import { defineComponent, ref } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'

export default defineComponent({
  components: {
    TmTable,
  },
  props: {
    statsFull: {
      type: Object as PropType<ReportingMessagesStatsFullLine[]>,
      required: true,
    },
  },
  setup() {
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Year', value: 'year-slot' },
      { text: 'Sent', value: 'sent' },
      { text: 'Received', value: 'received' },
      { text: 'Delivery rate', value: 'deliveryRate' },
      { text: 'Reply rate', value: 'replyRate' },
      { text: 'Costs', value: 'costs' },
    ])

    return {
      tableHeaders,
    }
  },
})
