import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8c00146"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_dropdown_download = _resolveComponent("tm-dropdown-download")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_field, {
      modelValue: _ctx.accounts,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accounts) = $event)),
      type: "selectSearchCategory",
      class: "search-category",
      size: "small"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_tm_dropdown_download, {
      size: "large",
      "icon-only": _ctx.isSmMax
    }, null, 8, ["icon-only"])
  ]))
}