
import {
  defineComponent, ref
} from 'vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import { getTableData } from '@/services/tableService'

const reportingCategories = getTableData('reportingCategories')

export default defineComponent({
  components: {
    TmDropdownDownload,
  },
  setup() {
    const accounts = ref(reportingCategories[0])
    const { isSmMax } = useBreakpoints()

    return {
      accounts,
      isSmMax,
    }
  },
})
